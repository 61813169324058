const COOKIES_AGREEMENT = "cookies_agreement";

$(document).ready(function () {
  initApp();

  // Quantity tool start
  $(".quantity-tool .more").on("click", function () {
    const counterEl = $(this).prev();
    const currentValue = parseInt(counterEl.html(), 10);

    if (currentValue < 99) {
      counterEl.html(currentValue + 1);
    }
  });

  $(".quantity-tool .less").on("click", function () {
    const counterEl = $(this).next();
    const currentValue = parseInt(counterEl.html(), 10);

    if (currentValue > 1) {
      counterEl.html(currentValue - 1);
    }
  });
  // Quantity tool end

  // Cookies start
  $("#cookies-save").on("click", function () {
    localStorage.setItem(COOKIES_AGREEMENT, true);
    closeCookiesPupup();
  });

  $("#cookies-close").on("click", function () {
    closeCookiesPupup();
  });
  // Cookies end

  // Product filters start
  $("#filter-column-mod").on("click", function () {
    const columnToggle = $(this).data("toggle");
    const columnTwo = $(this).children(".two");
    const columnOne = $(this).children(".one");
    const productWrapper = $("#products-list .product-wrapper");
    $(this).data("toggle", !columnToggle);

    if (columnToggle) {
      productWrapper.removeClass("col-6").addClass("col-12");
      columnOne.addClass("d-none");
      columnTwo.removeClass("d-none");
    } else {
      productWrapper.removeClass("col-12").addClass("col-6");
      columnOne.removeClass("d-none");
      columnTwo.addClass("d-none");
    }
  });
  // Product filters end
});

const initApp = () => {
  checkCookiesAgreement();
};

const checkCookiesAgreement = () => {
  const hasAgreedCookies = localStorage.getItem(COOKIES_AGREEMENT) === "true";

  if (!hasAgreedCookies) {
    $("#cookies-popup").addClass("cookies-popup--shown");
  }
};

const closeCookiesPupup = () => {
  $("#cookies-popup").removeClass("cookies-popup--shown");
};
