const swiperSliders = {
  // Slider for product details carousel
  productDetails: {
    name: ".swiper-product-details",
    init: function () {
      const imgSources = swiperSliders.getPaginationCaptions(this.name);
      new Swiper(this.name, {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="${className}"><img src="${imgSources[index]}" /></span>`;
          },
        },
        navigation: {
          nextEl: `${this.name}-button-next`,
          prevEl: `${this.name}-button-prev`,
        },
      });
    },
  },

  // Slider for home latest
  latest: {
    name: ".swiper-latest",
    init: function () {
      new Swiper(this.name, {
        ...swiperSliders.productsConfig,
        navigation: {
          nextEl: `${this.name}-button-next`,
          prevEl: `${this.name}-button-prev`,
        },
      });
    },
  },

  // Slider for home offers and detail offers
  offers: {
    name: ".swiper-offers",
    init: function () {
      new Swiper(this.name, {
        ...swiperSliders.productsConfig,
        navigation: {
          nextEl: `${this.name}-button-next`,
          prevEl: `${this.name}-button-prev`,
        },
      });
    },
  },

  // Slider for home banner
  banner: {
    name: ".swiper-banner",
    init: function () {
      new Swiper(this.name, {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 5000,
        },
      });
    },
  },

  // Slider for collection
  collection: {
    name: ".swiper-collection",
    init: function () {
      new Swiper(this.name, {
        ...swiperSliders.collectionConfig,
      });
    },
  },

  // Slider for collection alt
  collectionAlt: {
    name: ".swiper-collection-alt",
    init: function () {
      new Swiper(this.name, {
        ...swiperSliders.collectionConfig,
      });
    },
  },

  // Slider for product details zoom
  productZoom: {
    name: ".swiper-product-zoom",
    init: function () {
      new Swiper(this.name, {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 18,
        centeredSlides: true,
        navigation: {
          nextEl: `${this.name}-button-next`,
          prevEl: `${this.name}-button-prev`,
        },
      });
    },
  },

  get productsConfig() {
    return {
      loop: true,
      // Requires a minimum of 8 items in the slider to loop back to the start
      slidesPerView: 1.5,
      spaceBetween: 18,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 40,
          centeredSlides: false,
        },
      },
    };
  },

  get collectionConfig() {
    return {
      slidesPerView: 1.2,
      spaceBetween: 18,
      centeredSlides: false,
      breakpoints: {
        768: {
          slidesPerView: 2.2,
        },
        992: {
          slidesPerView: 3.2,
        },
      },
    };
  },

  getPaginationCaptions(slider) {
    return $(`${slider} .swiper-wrapper .image`).map((index, value) =>
      $(value)
        .css("background-image")
        .replace(/^url\(['"](.+)['"]\)/, "$1")
    );
  },

  initSliders: function () {
    this.productDetails.init();
    this.latest.init();
    this.offers.init();
    this.banner.init();
    this.collection.init();
    this.collectionAlt.init();
    this.productZoom.init();
  },
};

swiperSliders.initSliders();
